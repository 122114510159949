import useSWRImmutable from 'swr/immutable'
import { z } from 'zod'

import { ON_PROD } from '../env'
import { definitions, paths } from '../openapi/uniapiV2'
import { get } from '../xy3'

const traitSniperRankingDataZ = z
  .object({ rank: z.number(), url: z.string() })
  .nullish()
const traitSniperRankingPath: keyof paths = '/collection/v1/{address}/rank/ts'
export const useTraitSniperRanking = (
  contractAddress: string,
  params: paths[typeof traitSniperRankingPath]['get']['parameters']['query'],
) => {
  return useSWRImmutable(
    ON_PROD && {
      url: traitSniperRankingPath.replace('{address}', contractAddress),
      query: params,
    },
    async ({ url, query }) => {
      type Res =
        paths[typeof traitSniperRankingPath]['get']['responses'][200]['schema']
      const res = await get<Res>(url, query, null)
      if (res.status !== 200 || res.data.code !== 0) throw Error('not ok')
      return traitSniperRankingDataZ.parse(res.data.data)
    },
    { onError: (e) => console.error(e) },
  )
}

const raritySniperRankingDataZ = z
  .object({ rank: z.number(), url: z.string() })
  .nullish()
const raritySniperRankingPath: keyof paths = '/collection/v1/{address}/rank/rs'
export const useRaritySniperRanking = (
  contractAddress: string,
  params: paths[typeof raritySniperRankingPath]['get']['parameters']['query'],
) => {
  return useSWRImmutable(
    ON_PROD && {
      url: raritySniperRankingPath.replace('{address}', contractAddress),
      query: params,
    },
    async ({ url, query }) => {
      type Res =
        paths[typeof raritySniperRankingPath]['get']['responses'][200]['schema']
      const res = await get<Res>(url, query, null)
      if (res.status !== 200 || res.data.code !== 0) throw Error('not ok')
      return raritySniperRankingDataZ.parse(res.data.data)
    },
    { onError: (e) => console.error(e) },
  )
}

const rankingPath: keyof paths = '/collection/v1/ranking3/{platform}'
export type CollRankingOrderBy =
  paths[typeof rankingPath]['get']['parameters']['query']['orderBy']

type RankingProps = {
  platform: string
  orderBy: CollRankingOrderBy
  page: number
  pageSize: number
}
export const useRanking = ({
  platform,
  orderBy,
  page,
  pageSize,
}: RankingProps) =>
  useSWRImmutable(
    {
      url: rankingPath.replace('{platform}', platform),
      orderBy,
      page,
      pageSize,
    },
    async ({ url, orderBy, page, pageSize }) => {
      type Res = paths[typeof rankingPath]['get']['responses'][200]['schema']
      const res = await get<Res>(url, { orderBy, page, pageSize }, null)
      if (res.status !== 200 || res.data.code !== 0) throw Error('not ok')
      return res.data.data
    },
  )
export type CollRankingData = NonNullable<ReturnType<typeof useRanking>['data']>

export type EstPriceItem = definitions['NftEstPriceItem']
