import { useCallback } from 'react'
import { z } from 'zod'

import { useBrowserPreference } from '@/components/BrowserPreferenceCtx'

const withFallback = <T>(schema: z.ZodType<T>, fallback: T) =>
  z.preprocess(
    (value) => {
      const parseResult = schema.safeParse(value)
      if (parseResult.success) return value
      return fallback
    },
    z.custom<T>(() => true),
  )

const sortableZ = withFallback(
  z.enum([
    'volume_5m',
    'volume_15m',
    'volume_1h',
    'volume_1d',
    'volume_7d',
    'sales_5m',
    'sales_15m',
    'sales_1h',
    'sales_1d',
    'sales_7d',
  ]),
  'volume_1d',
)

export const useOrderBy = () => {
  const { value, set } = useBrowserPreference()
  const orderBy = sortableZ.parse(value.rankingOrderBy)
  const setOrderBy = useCallback(
    (a: string) => set({ rankingOrderBy: a }),
    [set],
  )
  return [orderBy, setOrderBy] as const
}
