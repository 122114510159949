import { Trans, useTranslation } from 'next-i18next'

import { ArrowRightSimpleLine } from '@/icons'
import * as cdnImages from '@/images/cdnImages'
import { resizedCdnUrl } from '@/lib/cdn'

export const FiBanner = () => {
  const { t } = useTranslation()

  return (
    <div
      className="relative mx-6 h-[156px] cursor-pointer overflow-hidden rounded-xl sm:mx-8 sm:h-[148px]"
      onClick={() => {
        window.open('https://fi.x2y2.io/buynowpaylater', '_blank')
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-[left_bottom] sm:hidden"
        style={{
          backgroundImage: `url('${resizedCdnUrl(
            cdnImages.fiBannerMobile,
            1440,
          )}')`,
        }}
      />
      <div
        className="hidden h-full bg-[length:auto_100%] bg-left sm:block"
        style={{
          backgroundImage: `url('${resizedCdnUrl(cdnImages.fiBanner, 2400)}')`,
        }}
      />
      <div className="absolute inset-0 flex w-[240px] px-6 py-8 sm:w-auto sm:items-center sm:px-10 sm:pl-[230px]">
        <h4 className="ts-headline-5 text-button-text sm:ts-headline-4 lg:ts-headline-2">
          <Trans t={t}>
            Get top NFTs now at just 10%!
            {/* <ArrowRightSimpleLine className="ml-1 inline-flex h-6 w-6 sm:hidden" /> */}
          </Trans>
        </h4>
      </div>
    </div>
  )
}
