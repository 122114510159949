import { pipe } from 'fp-ts/lib/function'
import { Trans, useTranslation } from 'next-i18next'
import { useMemo } from 'react'

import useOptionalTask from '@/hooks/useOptionalTask'
import { ArrowRightSquareFilled, LockFilled } from '@/icons'
import * as api from '@/lib/api'
import { getUserAddress, useAuth } from '@/lib/auth'
import { O, RD, TE } from '@/lib/fp'
import { userURL } from '@/lib/url'

import Link from '../TwLink'

export const PrivateSellNotificationBanner = () => {
  const { user } = useAuth()
  const task = useMemo(
    () =>
      pipe(
        getUserAddress(user),
        O.map((addr) =>
          pipe(
            TE.Do,
            TE.bind('addr', () => TE.right(addr)),
            TE.bind('hasPrivateOrder', () =>
              pipe(
                api.openedPrivateOrdersByTaker({ addr }),
                TE.map((a) => a.nft_order.length > 0),
              ),
            ),
          ),
        ),
      ),
    [user],
  )
  const [data] = useOptionalTask(task)
  if (RD.isSuccess(data) && data.value.hasPrivateOrder)
    return <Inner addr={data.value.addr} />
  return null
}

export const Inner = ({ addr }: { addr: string }) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center bg-blue-bg py-3 px-3 text-sm lg:px-6">
      <div className="flex flex-1 items-center space-x-3">
        <LockFilled className="h-6 w-6 text-primary-1" />
        <div className="text-gray-500">
          <Trans t={t}>
            There are private sale(s){' '}
            <strong className="text-gray-700">waiting for you to check</strong>.
          </Trans>
        </div>
      </div>
      <Link
        className="ts-button-2 ml-2 flex items-center space-x-1 justify-self-end text-primary-1"
        href={userURL(addr, {
          tab: 'offers',
          query: { type: 'private-sale', sort: 'created_at_desc' },
        })}
        hoverStyle="none"
      >
        <ArrowRightSquareFilled className="h-[14px] w-[14px]" />
        <span>{t('More')}</span>
      </Link>
    </div>
  )
}
