import { useTranslation } from 'next-i18next'

import { ArrowRightSimpleLine } from '@/icons'
import * as cdnImages from '@/images/cdnImages'
import { resizedCdnUrl } from '@/lib/cdn'

export const Royalties = () => {
  const { t } = useTranslation()

  return (
    <div
      className="mx-6 cursor-pointer rounded-xl bg-[length:100%_auto] bg-center p-6 lg:mx-8 lg:p-10"
      style={{
        backgroundImage: `url('${resizedCdnUrl(cdnImages.royaltyBg, 1440)}')`,
      }}
      onClick={() => {
        window.open(
          'https://docs.x2y2.io/guides/collection-management/manage-your-collection',
          '_blank',
        )
      }}
    >
      <h4 className="ts-headline-5 text-button-text lg:ts-headline-4">
        {t('Royalties are paid instantly')}
      </h4>
      <p className="ts-caption-2 mt-1 flex items-center text-button-text lg:ts-caption">
        <span>
          {t('Learn more about how to set royalties for your collections.')}
        </span>
        <ArrowRightSimpleLine className="ml-1 h-6 w-6 self-start" />
      </p>
    </div>
  )
}
