import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, Fragment } from 'react'

import { Empty, Loading } from '@/components/feedback'
import { ArrowDownSimpleFilled, ArrowUpSimpleFilled } from '@/icons'
import { CollRankingData, CollRankingOrderBy } from '@/lib/collection/api'

import { CollRankingItem } from './CollRankingItem'

type Header = {
  title: string
  order?: 'volume' | 'sales'
  hidden?: boolean
}

type Props = {
  className?: string
  query: { order: CollRankingOrderBy; sort: 'asc' | 'desc' }
  data: CollRankingData | undefined
  error: unknown
  isValidating: boolean
  isOverall: boolean
  isCompact?: boolean
  setOrderBy: (a: CollRankingOrderBy) => void
}
export const CollRankingList: FC<Props> = ({
  className,
  children,
  query,
  data,
  error,
  isValidating,
  isOverall,
  isCompact = false,
  setOrderBy,
}) => {
  const { t } = useTranslation()
  // TODO: fix typing
  const scope = query.order.split('_')[1] as '5m' | '15m' | '1h' | '1d' | '7d'

  const headers: Header[] = [
    ...(isOverall
      ? ([
          { title: t('Floor Price') },
          { title: t('1D Change') },
          { title: t('7D Change'), hidden: isCompact },
        ] as Header[])
      : []),
    { title: t('Volume'), order: 'volume' },
    { title: t('Sales'), order: 'sales' },
    { title: t('Owners') },
    { title: t('Items') },
  ]

  const sortStyle = (order: CollRankingOrderBy, sort: 'asc' | 'desc') =>
    query.order === order && query.sort === sort
      ? /*tw:*/ 'text-primary-1'
      : /*tw:*/ 'text-gray-400'
  const genSortIndicator = (order: CollRankingOrderBy) => (
    <span className="ml-1 mb-0.5 inline-flex flex-col align-text-bottom">
      <ArrowUpSimpleFilled
        className={clsx('-mt-0.5 h-4 w-4', sortStyle(order, 'asc'))}
      />
      <ArrowDownSimpleFilled
        className={clsx('-mt-2.5 -mb-1 h-4 w-4', sortStyle(order, 'desc'))}
      />
    </span>
  )

  return (
    <section className={clsx(className, 'flex flex-col')}>
      <header className="ts-caption-2 sticky top-20 z-10 hidden items-center space-x-4 bg-bg px-4 text-right font-bold text-gray-500 lg:flex">
        <div className="flex-1 grow-[3] overflow-hidden text-left">
          {t('Collection')}
        </div>
        {headers.map(({ title, order, hidden }, i) => {
          const className = clsx(
            'flex-1 overflow-hidden',
            hidden ? 'hidden' : 'flex',
          )
          return order ? (
            <button
              className={clsx(className, 'items-center text-right')}
              onClick={() => setOrderBy(`${order}_${scope}`)}
              key={i}
            >
              <span className="flex-1 truncate">{title}</span>
              {genSortIndicator(`${order}_${scope}`)}
            </button>
          ) : (
            <div className={clsx(className, 'justify-end truncate')} key={i}>
              {title}
            </div>
          )
        })}
      </header>
      {isValidating ? (
        <Loading />
      ) : error ? (
        <Empty label={t("Something's wrong")} />
      ) : !data?.data || data.data.items.length === 0 ? (
        <Empty />
      ) : (
        <ul>
          {data.data.items.map((data, i) => (
            <Fragment key={i}>
              {i > 0 && <hr className="mx-3 lg:mx-4" />}
              <CollRankingItem
                {...{ data, scope, isOverall, isCompact }}
                key={i}
              />
            </Fragment>
          ))}
        </ul>
      )}
      {children}
    </section>
  )
}
