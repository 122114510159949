import { useTranslation } from 'next-i18next'
import useSWR from 'swr'

import { ReactComponent as LoanCollections } from '@/images/LoanCollections.svg'
import { fmtNumber, fmtPercentage } from '@/lib/formatter'
import { LOAN_STATS_URL } from '@/lib/loan/apiPaths'
import { loanURL } from '@/lib/url'
import { getSysStats } from '@/lib/xy3'

import { ButtonLink } from '../form'

export const LoanStats = () => {
  const { t } = useTranslation()

  const { data: stats } = useSWR(LOAN_STATS_URL, getSysStats)

  const statItems = [
    [
      {
        label: t('Total borrowed'),
        value: stats?.totalBorrowed
          ? fmtNumber(stats?.totalBorrowed, { max: 2 })
          : '-',
        showNumSymbol: true,
      },
      {
        label: t('Loans num'),

        value: stats?.loanNum ? fmtNumber(stats.loanNum) : '-',
        showNumSymbol: false,
      },
    ],
    [
      {
        label: t('Recent APR'),
        value: stats?.avgAPR ? fmtPercentage(stats.avgAPR / 100, 2) : '-',
        showNumSymbol: false,
      },
      {
        label: t('Accrued interest'),
        value: stats?.totalInterest
          ? fmtNumber(stats.totalInterest, {
              max: 2,
            })
          : '-',
        showNumSymbol: true,
      },
    ],
  ]

  return (
    <div className="relative h-[368px] overflow-hidden bg-blue-bg px-6 py-10 md:rounded-xl md:px-0 lg:h-auto lg:py-12">
      <LoanCollections className="absolute top-[-25px] left-[-71px] hidden w-[440px] rotate-[30deg] lg:block" />
      <LoanCollections className="absolute bottom-[-25px] right-[-71px] hidden w-[440px] rotate-[30deg] lg:block" />
      <div className="isolate z-[1] flex flex-col items-center">
        <h4 className="ts-headline-5 text-gray-800 lg:ts-headline-4">
          {t('NFT Loan')}
        </h4>
        <p className="ts-caption-2 mt-1 text-gray-500 lg:ts-caption">
          {t('A new way to leverage your assets')}
        </p>
        <div className="mt-6 flex w-full flex-wrap items-center justify-center gap-y-4 rounded-xl bg-bg px-2 py-4 md:w-[696px] md:px-3 md:py-6">
          {statItems.map((r, i) => (
            <div
              key={i}
              className="flex min-w-0 flex-[1_0_336px] items-center justify-around"
            >
              {r.map((a, j) => (
                <div
                  key={j}
                  className="flex min-w-0 flex-[1_0_0] flex-col items-center px-2"
                >
                  <p className="ts-caption-2 truncate leading-5 text-gray-500">
                    {a.label}
                  </p>
                  <p className="ts-headline-6 flex max-w-full text-base text-gray-700 lg:ts-headline-5">
                    <span className="truncate">{a.value}</span>
                    {a.showNumSymbol && <span className="ml-1">Ξ</span>}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="mt-6 flex space-x-4 lg:max-w-[327px]">
          <ButtonLink
            className="GA-home-borrow ts-body-1 flex-1"
            size="md"
            colorScheme="primary-1"
            href={loanURL()}
          >
            {t('Borrow')}
          </ButtonLink>
          <ButtonLink
            className="GA-home-lend ts-body-1 flex-1"
            size="md"
            colorScheme="primary-1"
            href={loanURL({ category: 'supported' })}
          >
            {t('Lend')}
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
