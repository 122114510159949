import { useState } from 'react'

import { useColorModeValue } from '@/components/colorMode'
import { CdnImage } from '@/components/media'
import Link from '@/components/TwLink'
import { partners as partnersImages } from '@/images/cdnImages'
import { CdnFile } from '@/lib/cdn'

type Item = {
  logo: keyof typeof partnersImages.light
  link: string
}
const ITEMS: Item[] = [
  {
    logo: '3GemLogo',
    link: 'https://gem.xyz',
  },
  {
    logo: '4NFTsenseiLogo',
    link: 'https://nftsensei.xyz',
  },
  {
    logo: '19TokunLogo',
    link: 'https://tokun.app/',
  },
  {
    logo: '2UniswapLogo',
    link: 'https://uniswap.org/',
  },
  {
    logo: '13NftgoLogo',
    link: 'https://nftgo.io',
  },
  {
    logo: '12Go+Logo',
    link: 'https://gopluslabs.io/',
  },
  {
    logo: '6TraitsniperLogo',
    link: 'https://traitsniper.com',
  },
  {
    logo: '10RaritysniperLogo',
    link: 'https://raritysniper.com',
  },
  {
    logo: '1OpenseaLogo',
    link: 'https://opensea.io',
  },
  {
    logo: '5NfttrackLogo',
    link: 'https://nfttrack.ai',
  },
  {
    logo: '7NftscanLogo',
    link: 'https://nftscan.com',
  },
  {
    logo: '8NftnerdsLogo',
    link: 'https://nftnerds.ai',
  },
  {
    logo: '9TinyastroLogo',
    link: 'https://tinyastro.io/',
  },
  {
    logo: '11NansenLogo',
    link: 'https://nansen.ai/',
  },
  {
    logo: '15NfteyeLogo',
    link: 'https://nfteye.io',
  },
  {
    logo: '16B4fomoNFTLogo',
    link: 'https://b4fomo.io/',
  },
  {
    logo: '17DefinedLogo',
    link: 'https://defined.fi/',
  },
  {
    logo: '18NFTscoringLogo',
    link: 'https://nftscoring.com',
  },

  {
    logo: '20AtlasLogo',
    link: 'https://www.atlas.xyz',
  },
  {
    logo: 'fusionxyzLogo',
    link: 'https://www.fusion.xyz/',
  },
  {
    logo: 'nftflipaiLogo',
    link: 'http://nftflip.ai/',
  },
]

export const Partners = () => {
  const isLightMode = useColorModeValue(true, false)
  const items = Object.entries(
    isLightMode ? partnersImages.light : partnersImages.dark,
  )
  const ordKey = (k: string) => parseInt(k.match(/^(\d+)+/g)?.[0] ?? '0')
  items.sort(([k1], [k2]) => ordKey(k1) - ordKey(k2))

  return (
    <div className="grid grid-cols-partners gap-6 px-6 xl:px-0">
      {ITEMS.map(({ logo, link }, idx) => (
        <Partner
          key={idx}
          image={
            (isLightMode ? partnersImages.light : partnersImages.dark)[logo]
          }
          activeImage={
            (isLightMode
              ? partnersImages['light-active']
              : partnersImages['dark-active'])[logo]
          }
          link={link}
        />
      ))}
    </div>
  )
}

const Partner = ({
  image,
  activeImage,
  link,
}: {
  image: CdnFile
  activeImage: CdnFile
  link: string
}) => {
  const [active, setActive] = useState(false)
  return (
    <div
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Link href={link} className="mx-auto block h-[40px] w-[100px]" isExternal>
        <CdnImage
          className="object-center"
          cdnSrc={active ? activeImage : image}
          objectFit="contain"
        />
      </Link>
    </div>
  )
}
