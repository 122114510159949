import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Swiper, { Autoplay } from 'swiper'
import { SwiperSlide, Swiper as SwpiperEle } from 'swiper/react'

import { useOnPC } from '@/hooks'
import { ArrowRightSimpleLine, CheckLine, VerifiedFilled } from '@/icons'
import { resizedCdnUrl } from '@/lib/cdn'
import { FeaturedContract } from '@/lib/home/types'
import { collectionURL } from '@/lib/url'

import { Img } from '../media'
import Link from '../TwLink'

export const Recommend = ({ contracts }: { contracts: FeaturedContract[] }) => {
  const { t } = useTranslation()
  const cards = useMemo(
    () =>
      Array<FeaturedContract[]>(Math.ceil(6 / contracts.length))
        .fill(contracts)
        .flat(),
    [contracts],
  )

  const swiperRef = useRef<Swiper | null>(null)
  const [defaultItem, setDefaultItem] = useState<{
    index: number
    data: (typeof cards)[number]
  }>({ index: 0, data: cards[0] })
  const [selectedItem, setSelectedItem] = useState<{
    index: number
    data: (typeof cards)[number]
  }>(defaultItem)

  const onPC = useOnPC('lg')
  const handleActiveIndexChange = useCallback(
    (swiper: Swiper) => {
      const index = onPC
        ? swiper.realIndex > 0
          ? swiper.realIndex - 1
          : cards.length - 1
        : swiper.realIndex
      const item = { index, data: cards[index] }
      setDefaultItem(item)
      setSelectedItem(item)
    },
    [cards, onPC],
  )
  useEffect(() => {
    if (swiperRef.current) {
      handleActiveIndexChange(swiperRef.current)
    }
  }, [onPC, handleActiveIndexChange])

  return (
    <div className="relative overflow-hidden lg:mx-8 lg:rounded-xl">
      <div
        className="absolute inset-[-40px] z-[-1] flex items-center justify-center bg-center blur-2xl filter transition-[background-image] duration-300 will-change-transform"
        style={{
          backgroundImage: `url('${selectedItem.data.image_url}')`,
          backgroundSize: 'cover',
        }}
      >
        <div className="absolute inset-0 bg-black/[.24]"></div>
      </div>
      <div className="flex flex-col py-10 lg:flex-row">
        <div className="flex flex-1 flex-col justify-between px-6 lg:px-10">
          <h4 className="ts-headline-5 font-bold text-button-text lg:ts-headline-4">
            {t('Recommend')}
          </h4>
          <div className="hidden lg:flex lg:flex-col">
            <div className="flex items-center space-x-3">
              <Img
                className="h-10 w-10 shrink-0 overflow-hidden rounded-full"
                src={resizedCdnUrl(selectedItem.data.logo_url, 128)}
              />
              <span className="ts-body-2 font-bold text-button-text line-clamp-2">
                {selectedItem.data.name}
              </span>
            </div>
            {selectedItem.data.desc && (
              <p className="ts-caption mt-4 flex-1 text-button-text line-clamp-5">
                {selectedItem.data.desc}
              </p>
            )}
          </div>
        </div>
        <div className="relative mt-6 flex min-w-0 justify-center overflow-hidden lg:mt-0 lg:pr-10">
          <div className="flex justify-center overflow-hidden lg:w-[584px] lg:justify-start xl:w-[888px]">
            <div className="w-[912px] will-change-transform lg:-translate-x-3">
              <SwpiperEle
                onSwiper={(swiper) => {
                  swiperRef.current = swiper
                }}
                modules={[Autoplay]}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                loop
                onActiveIndexChange={handleActiveIndexChange}
                slidesPerView={3}
                centeredSlides
              >
                {cards.map((item, i) => (
                  <SwiperSlide
                    className="!w-[304px] px-3"
                    onMouseEnter={() => {
                      setSelectedItem({ index: i, data: item })
                    }}
                    onMouseLeave={() => {
                      setSelectedItem(defaultItem)
                    }}
                    key={i}
                  >
                    <div className="group overflow-hidden rounded-xl bg-bg link-box">
                      <div className="aspect-w-1 aspect-h-1 overflow-hidden">
                        <Img
                          className="object-cover transition-all lg:group-hover:scale-110"
                          src={item.image_url}
                        />
                      </div>
                      <div className="flex items-center p-4">
                        <Link
                          className="GA-home-main-ad ts-body-3 truncate font-bold link-overlay lg:ts-body-2"
                          hoverStyle="none"
                          href={
                            item.url ??
                            (item.slug &&
                              collectionURL({
                                slug: item.slug,
                                tab: 'items',
                              })) ??
                            '#'
                          }
                          isExternal={item.url !== undefined}
                        >
                          {item.name}
                        </Link>
                        {item.verified && (
                          <VerifiedFilled className="h-6 w-6 pl-1 text-primary-1" />
                        )}
                      </div>
                      {selectedItem.index === i && (
                        <span className="absolute bottom-[68px] right-3 flex h-6 w-6 items-center justify-center rounded-full border-2 border-button-text bg-button-text/25">
                          <CheckLine className="h-4 w-4 text-button-text" />
                        </span>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </SwpiperEle>
            </div>
          </div>
          <ArrowRightSimpleLine
            className="absolute right-5 top-[148px] z-[1] hidden h-10 w-10 cursor-pointer select-none rounded-full border border-gray-300 bg-bg p-2 text-gray-500 lg:block"
            onClick={() => swiperRef.current?.slideNext()}
          />
        </div>
      </div>
    </div>
  )
}
