import clsx from 'clsx'
import { Trans, useTranslation } from 'next-i18next'
import { Fragment } from 'react'

import { useFromNow } from '@/hooks'
import { InfoCircleLine } from '@/icons'
import { ReactComponent as MarketStatsBgLeft } from '@/images/MarketStatsBgLeft.svg'
import { ReactComponent as MarketStatsBgRight } from '@/images/MarketStatsBgRight.svg'
import { fmtCurrencyNumber, fmtNumber, fmtPercentage } from '@/lib/formatter'
import { rewardsURL } from '@/lib/url'
import * as cmp from '@/lib/x2y2/compounder'
import { Estimates } from '@/lib/x2y2/feeSharing'

import { ButtonLink } from '../form'
import { Tooltip } from '../overlay'

export type Stats = {
  timestamp: number
  gas: number
  gas_today: number
  market_fee: number
  trade_nft_count: number
  tx_count: number
  user_count: number
  vol: number
}

type Props = {
  stats: Stats
  estimates: Estimates
}
export const MarketStats = ({ stats, estimates }: Props) => {
  const { t } = useTranslation()
  const fromNow = useFromNow()

  const compounderAPY = cmp.compounderAPY({
    WETH: estimates.wethAPR,
    X2Y2: estimates.x2y2APR,
  }).totalAPY
  const statItems = [
    {
      label: t('Vol'),
      value: fmtNumber(stats.vol, { max: 1 }),
      hint: t('Last 24h'),
      showNumSymbol: true,
    },
    {
      label: t('Fee'),
      value: fmtNumber(stats.market_fee / 1_000_000_000_000_000_000, {
        max: 2,
      }),
      hint: t('updated {{time}}', { time: fromNow(stats.timestamp * 1000) }),
      showNumSymbol: true,
    },
    {
      label: t('Est. APY'),
      value: fmtPercentage(compounderAPY),
      valueColored: true,
      hint: t('X2Y2 Staking'),
      tooltip: (
        <div className="ts-caption-2 space-y-2 [&_strong]:text-gray-100">
          <p>
            <Trans t={t}>
              {{
                value:
                  '$' +
                  fmtCurrencyNumber(estimates.dailyUSDPer10000x2y2, 'USD'),
              }}{' '}
              in X2Y2 daily per 10,000 X2Y2 staked in compounder.
            </Trans>
          </p>
          <hr className="border-gray-700" />
          <p>
            <Trans t={t}>
              The total APY for X2Y2 compounder includes X2Y2 staking rewards
              and market fee rewards (WETH) auto compounded.(Since 00:00 UTC)
            </Trans>
          </p>
          <p>
            {t('X2Y2 APR')}
            <br />
            <strong>{fmtPercentage(estimates.x2y2APR)}</strong>
          </p>
          <p>
            {t('WETH APR')}
            <br />
            <strong>{fmtPercentage(estimates.wethAPR)}</strong>
          </p>
          <p>
            {t('WETH APY')}
            <br />
            <strong>{fmtPercentage(estimates.wethAPY)}</strong>
          </p>
          <p>
            {t('Total APY = (1 + X2Y2 APR) * (1 + WETH APY) - 1')}
            <br />
            <strong>{fmtPercentage(compounderAPY)}</strong>
          </p>
        </div>
      ),
    },
  ]

  return (
    <div className="relative h-[368px] overflow-hidden bg-blue-bg px-6 py-10 md:rounded-xl md:px-0 lg:h-auto lg:py-12">
      <MarketStatsBgLeft className="absolute top-0 left-0 hidden w-[312px] lg:block" />
      <MarketStatsBgRight className="absolute bottom-0 right-0 hidden w-[312px] lg:block" />
      <div className="isolate z-[1] flex flex-col items-center">
        <h4 className="ts-headline-5 text-gray-800 lg:ts-headline-4">
          {t('Market')}
        </h4>
        <p className="ts-caption-2 mt-1 text-gray-500 lg:ts-caption">
          {t('100% trading fee rebate for all collections')}
        </p>
        <div className="mt-6 flex w-full items-center justify-around rounded-xl bg-bg px-2 py-[28px] md:w-[696px] md:px-3 md:py-6 lg:py-[14px]">
          {statItems.map((a, i) => (
            <Fragment key={i}>
              <Tooltip label={a.tooltip} hasArrow placement="top">
                <div className="flex min-w-0  flex-col items-center px-2">
                  <div className="flex items-center">
                    <p className="ts-hairline-3 truncate leading-5 text-gray-500">
                      {a.label}
                    </p>
                    {a.tooltip && (
                      <InfoCircleLine className="ml-2 h-4 w-4 text-gray-500" />
                    )}
                  </div>
                  <p
                    className={clsx(
                      'ts-headline-6 flex max-w-full text-base text-gray-700 lg:ts-headline-5',
                      a.valueColored &&
                        'bg-gradient-to-r from-[#3772FF] to-primary-2 bg-clip-text text-transparent',
                    )}
                  >
                    <span className="truncate">{a.value}</span>
                    {a.showNumSymbol && <span className="ml-1">Ξ</span>}
                  </p>
                  <p className="ts-caption-2 truncate text-gray-400">
                    {a.hint}
                  </p>
                </div>
              </Tooltip>
            </Fragment>
          ))}
        </div>
        <div className="mt-6 flex">
          <ButtonLink
            className="GA-home-borrow ts-body-1 flex-1"
            size="md"
            colorScheme="primary-1"
            href={rewardsURL({})}
          >
            {t('Get Rewards')}
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
