import { useTranslation } from 'next-i18next'
import { useMemo, useRef } from 'react'
import Swiper, { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import { SwiperSlide, Swiper as SwpiperEle } from 'swiper/react'

import {
  ArrowLeftSimpleLine,
  ArrowRightSimpleLine,
  VerifiedFilled,
} from '@/icons'
import { TrendingContract } from '@/lib/home/types'
import { collectionURL } from '@/lib/url'
import { toChecksumAddress } from '@/lib/web3'

import { Img } from '../media'
import Link from '../TwLink'

export const Trending = ({ contracts }: { contracts: TrendingContract[] }) => {
  const { t } = useTranslation()
  const cards = useMemo(
    () =>
      Array<TrendingContract[]>(Math.ceil(8 / contracts.length))
        .fill(contracts)
        .flat(),
    [contracts],
  )

  const swiperRef = useRef<Swiper | null>(null)

  return (
    <div className="relative">
      <div className="flex justify-center overflow-hidden lg:mx-8">
        <div className="w-[1400px] translate-x-[175px] will-change-transform lg:translate-x-0">
          <SwpiperEle
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop
            slidesPerView={4}
          >
            {cards.map((item, i) => (
              <SwiperSlide className="!w-[350px] px-3" key={i}>
                <div className="group overflow-hidden rounded-xl border border-gray-300 bg-bg link-box">
                  <div className="aspect-w-1 aspect-h-1 overflow-hidden">
                    <Img
                      className="object-cover transition-all lg:group-hover:scale-110"
                      src={item.url}
                    />
                    <div className="absolute inset-0 flex items-end">
                      <div className="flex h-[120px] w-full flex-col justify-end bg-gradient-to-t from-[rgba(0,0,0,0.48)] to-transparent p-4">
                        <p className="flex items-center">
                          <Link
                            className="GA-home-main-ad ts-body-3 truncate font-bold text-button-text line-clamp-2 link-overlay lg:ts-body-2"
                            hoverStyle="none"
                            href={collectionURL({
                              slug:
                                item.slug ?? toChecksumAddress(item.contract),
                              tab: 'items',
                            })}
                          >
                            {item.name}
                          </Link>
                          {item.verified && (
                            <VerifiedFilled className="ml-1 h-6 w-6 text-primary-1" />
                          )}
                        </p>
                        {item.floor_price && (
                          <p className="ts-body-3 mt-1 text-button-text">
                            {t('Floor')} {item.floor_price} Ξ
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </SwpiperEle>
        </div>
      </div>
      <ArrowLeftSimpleLine
        className="absolute left-3 top-[143px] z-[1] hidden h-10 w-10 cursor-pointer select-none rounded-full border border-gray-300 bg-bg p-2 text-gray-500 lg:block"
        onClick={() => swiperRef.current?.slidePrev()}
      />
      <ArrowRightSimpleLine
        className="absolute right-3 top-[143px] z-[1] hidden h-10 w-10 cursor-pointer select-none rounded-full border border-gray-300 bg-bg p-2 text-gray-500 lg:block"
        onClick={() => swiperRef.current?.slideNext()}
      />
    </div>
  )
}
