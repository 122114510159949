import { tuple } from 'fp-ts/function'
import { useEffect, useState } from 'react'

export const useTimeDiff = (time: Date, target: Date, onReach?: () => void) => {
  const diff = target.valueOf() - time.valueOf()
  const [reached, setReached] = useState(false)
  useEffect(() => {
    if (reached || diff > 0) return
    setReached(true)
    onReach?.()
  }, [reached, diff, onReach])
  return tuple(diff, reached)
}

export type CountdownProps = {
  start: Date
  end: Date
  onStart?: () => void
  onEnd?: () => void
  skipDays?: boolean
}

export type DigitsProps = {
  label: string
  number: string
}

export const useCountdown = ({
  start,
  end,
  onStart,
  onEnd,
  skipDays = false,
}: CountdownProps) => {
  const [time, setTime] = useState(new Date())
  useEffect(() => {
    if (new Date() > end) return
    const handle = setInterval(() => setTime(new Date()), 1_000)
    return () => clearInterval(handle)
  }, [end])
  const [diffStart, started] = useTimeDiff(time, start, onStart)
  const [diffEnd] = useTimeDiff(time, end, onEnd)
  const diff = started ? diffEnd : diffStart
  const seconds = Math.max(Math.floor(diff / 1000), 0)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const pad = (n: number) => (n < 10 ? '0' + n : n.toString())
  return {
    days: skipDays ? '' : days.toString(),
    hours: skipDays ? pad(hours) : pad(hours % 24),
    minutes: pad(minutes % 60),
    seconds: pad(seconds % 60),
  }
}
