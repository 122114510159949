import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useCallback, useEffect, useState } from 'react'

import {
  BellFilled,
  DiscordFilled,
  InstagramFilled,
  TwitterFilled,
  VerifiedFilled,
} from '@/icons'

import { CountdownProps, useCountdown } from '../Countdown'
import Link from '../TwLink'

const data = {
  name: 'Tarzan',
  price: '0.045 Ξ',
  start: new Date('2023-03-30T14:00:00.000Z'),
  website: 'https://mint.tarzanofficial.io/',
  twitter: 'https://twitter.com/Tarzan_io',
  discord: 'https://discord.com/invite/xlabel',
  instagram: 'https://www.instagram.com/xlabel_____/',
  logo: '/tarzan-logo.png',
  poster: '/tarzan-poster.jpg',
  video:
    'https://player.vimeo.com/progressive_redirect/playback/802404652/rendition/1080p/file.mp4?loc=external&signature=d9aa7a62d2bd47a780bda28766ee2f1d0917ed8b8607554b7a683d2b2de85061',
  calendar:
    'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20230330T140000Z%2F20230330T141500Z&details=Pre%20sale%20price%3A%200.045%20ETH%0A&location=https%3A%2F%2Ftarzanofficial.io%2F&text=Tarzan%20Pre%20Sale',
}

export const RecentMint = () => {
  const { t } = useTranslation()
  const links = [
    { href: data.twitter, icon: TwitterFilled },
    { href: data.discord, icon: DiscordFilled },
    { href: data.instagram, icon: InstagramFilled },
  ]
  const [started, setStarted] = useState<boolean | null>(null)
  useEffect(() => setStarted(+Date.now() > +data.start), [])
  const onEnd = useCallback(() => setStarted(true), [])
  return (
    <section className="group relative w-full self-center text-button-text link-box lg:my-8 lg:max-w-[min(1376px,100vw-64px)]">
      <video
        className="h-[480px] w-full object-cover lg:h-[360px] lg:rounded-xl"
        poster={data.poster}
        autoPlay
        muted
      >
        <source src={data.video} />
      </video>
      <div className="absolute inset-0 flex flex-col justify-between p-6 pt-10 lg:p-10">
        <div className="flex">
          <h2 className="ts-headline-5 flex-1 lg:ts-headline-4">
            {t('Recent Mint')}
          </h2>
          {started === false && (
            <Link
              className="grid h-8 w-8 place-items-center rounded-full bg-white/[.12] lg:h-10 lg:w-10"
              hoverStyle="opacity"
              href={data.calendar}
              isExternal
            >
              <BellFilled className="h-4 w-4 lg:h-6 lg:w-6" />
            </Link>
          )}
        </div>
        <div className="flex flex-col justify-between gap-6 lg:flex-row">
          <div className="flex items-center gap-3">
            <Image width={48} height={48} src={data.logo} alt={data.name} />
            <div className="flex flex-col gap-1">
              <Link
                className="ts-body-3 font-bold link-overlay lg:ts-body-2"
                hoverStyle="none"
                href={data.website}
                isExternal
              >
                {data.name}
                <VerifiedFilled className="ml-1 inline h-4 w-4 text-primary-1" />
              </Link>
              <div className="flex items-center gap-2">
                <p className="ts-body-3">{data.price}</p>
                {links.map((x, i) => (
                  <Link hoverStyle="opacity" href={x.href} isExternal key={i}>
                    <x.icon className="h-4 w-4 text-gray-500" />
                  </Link>
                ))}
              </div>
            </div>
          </div>
          {started === true && (
            <div className="ts-button-1 w-[198px] rounded-xl bg-white/[.12] py-[22px] text-center transition-colors group-hover:bg-white group-hover:text-black lg:py-[26px]">
              {t('Minting Now')}
            </div>
          )}
          {started === false && (
            <Countdown start={new Date(0)} end={data.start} onEnd={onEnd} />
          )}
        </div>
      </div>
    </section>
  )
}

const Countdown = (props: CountdownProps): JSX.Element => {
  const { t } = useTranslation()
  const { days, hours, minutes, seconds } = useCountdown({ ...props })
  return (
    <p className="flex w-[196px] gap-3 rounded-xl bg-white/[.12] py-2 px-4 uppercase transition-colors group-hover:bg-white group-hover:text-black">
      <div className="flex w-8 flex-col items-center">
        <p className="ts-body-2 font-bold lg:ts-headline-5">{+days}</p>
        <p className="ts-caption-2 text-gray-500">{t('days')}</p>
      </div>
      <div className="flex w-8 flex-col items-center">
        <p className="ts-body-2 font-bold lg:ts-headline-5">{+hours}</p>
        <p className="ts-caption-2 text-gray-500">{t('hrs')}</p>
      </div>
      <div className="flex w-8 flex-col items-center">
        <p className="ts-body-2 font-bold lg:ts-headline-5">{+minutes}</p>
        <p className="ts-caption-2 text-gray-500">{t('mins')}</p>
      </div>
      <div className="flex w-8 flex-col items-center">
        <p className="ts-body-2 font-bold lg:ts-headline-5">{+seconds}</p>
        <p className="ts-caption-2 text-gray-500">{t('secs')}</p>
      </div>
    </p>
  )
}
