import clsx from 'clsx'
import { useTranslation } from 'next-i18next'

import { Img } from '@/components/media'
import Link from '@/components/TwLink'
import { VerifiedFilled } from '@/icons'
import { useUFO } from '@/images/UFOImage'
import { resizedCdnUrl } from '@/lib/cdn'
import { CollRankingData } from '@/lib/collection/api'
import { fmtChangePercent, fmtLargeNumber, fmtNumber } from '@/lib/formatter'
import { collectionURL } from '@/lib/url'
import { toChecksumAddress } from '@/lib/web3'

type FmtType = 'eth' | 'change' | 'num'
type Item = {
  type: FmtType
  field: number | undefined
  maxFracDigits?: number
  extra?: number | undefined
  hidden?: boolean
}
type MobileItem = {
  title: string
  type: FmtType
  field: number | undefined
  maxFracDigits?: number
}

type Props = {
  data: NonNullable<CollRankingData['data']>['items'][0]
  scope: '5m' | '15m' | '1h' | '1d' | '7d'
  isOverall: boolean
  isCompact: boolean
}
export const CollRankingItem = ({
  data,
  scope,
  isOverall,
  isCompact,
}: Props) => {
  const { t } = useTranslation()
  const { contract, slug, iconUrl, verified } = data.collection

  const floorPrice = data.floor?.price
  const floor = floorPrice ? Number(floorPrice) : undefined
  const volume = data.volume ? data.volume * 1e18 : undefined
  const items: Item[] = [
    ...(isOverall
      ? ([
          { type: 'eth', field: floor, maxFracDigits: 4 },
          { type: 'change', field: data.floor_1d_diff },
          { type: 'change', field: data.floor_7d_diff, hidden: isCompact },
        ] as Item[])
      : []),
    { type: 'eth', field: volume, extra: data.volumePercentChange },
    { type: 'num', field: data.sales, extra: data.salesPercentChange },
    { type: 'num', field: data.owners },
    { type: 'num', field: data.items },
  ]
  const mobileItems: MobileItem[] = [
    {
      title: t('Floor'),
      type: 'eth',
      field: floor,
      maxFracDigits: 4,
    },
    { title: t('Sales'), type: 'num', field: data.sales },
  ]

  const ufoImage = useUFO()
  const changeStyle = (a: number) => {
    if (a > 0) return /*tw:*/ 'text-primary-4'
    if (a < 0) return /*tw:*/ 'text-primary-3'
  }
  const fmtVal = (a: number | null, type: FmtType, maxFracDigits = 2) =>
    a === null
      ? '-'
      : type === 'eth'
      ? `${fmtNumber(a / 1e18, { max: maxFracDigits })} Ξ`
      : type === 'change'
      ? fmtChangePercent(a)
      : fmtLargeNumber(a)

  return (
    <li className="flex items-center space-x-4 rounded-xl py-4 px-3 link-box hover:bg-gray-200 lg:px-4">
      <div className="flex flex-1 grow-[3] items-center overflow-hidden">
        <p className="ts-caption-2 w-7 shrink-0 font-bold text-gray-500 lg:w-9 lg:text-sm">
          {data.rank}
        </p>
        <Img
          className="h-10 w-10 shrink-0 overflow-hidden rounded-full"
          src={resizedCdnUrl(iconUrl ?? ufoImage, 128)}
        />
        <div className="ml-3 flex min-w-0 flex-1 flex-col">
          <div className="flex items-center">
            <Link
              className="GA-hot-collections ts-body-3 truncate break-all font-bold text-gray-900 link-overlay lg:ts-body-2"
              hoverStyle="none"
              href={collectionURL({
                tab: 'items',
                slug: slug ?? toChecksumAddress(contract),
              })}
            >
              {data.collection.name}
            </Link>
            {verified && (
              <VerifiedFilled className="ml-1 h-4 w-4 shrink-0 text-primary-1 lg:h-6 lg:w-6" />
            )}
            <p className="ts-caption-2 ml-auto shrink-0 truncate pl-3 text-gray-500 lg:hidden">
              {t('Vol.')}{' '}
              <strong className="text-gray-700">
                {fmtVal(volume ?? null, 'eth')}
              </strong>
            </p>
          </div>
          <div className="mt-1 flex flex-1 items-center justify-between gap-4 lg:hidden">
            {mobileItems.map((a, i) => {
              const f = a.field ?? null
              return (
                <div
                  className="ts-caption-2 flex items-center gap-1 overflow-hidden"
                  key={i}
                >
                  <p className="truncate text-gray-500">{a.title}</p>
                  <p className="truncate text-gray-700">
                    {fmtVal(f, a.type, a.maxFracDigits)}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {items.map((a, i) => {
        const f1 = a.field ?? null
        const f2 = a.extra ?? null
        return (
          <div
            className={clsx(
              'hidden flex-1 flex-col overflow-hidden text-right',
              !a.hidden && 'lg:flex',
            )}
            key={i}
          >
            <p
              className={clsx(
                'ts-body-3 truncate font-bold',
                f1 === null
                  ? 'text-gray-500'
                  : a.type === 'change'
                  ? changeStyle(f1)
                  : 'text-gray-700',
              )}
            >
              {fmtVal(f1, a.type, a.maxFracDigits)}
            </p>
            {f1 !== null && f2 !== null && (
              <p
                className={clsx(
                  'ts-caption-2 truncate',
                  f2 === 0 ? 'text-gray-700' : changeStyle(f2),
                )}
              >
                {fmtVal(f2, 'change')}
              </p>
            )}
          </div>
        )
      })}
    </li>
  )
}
