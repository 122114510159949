import clsx from 'clsx'
import { pipe } from 'fp-ts/function'
import { InferGetServerSidePropsType, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { Pagination } from 'swiper'
import 'swiper/css/pagination'
import { SwiperSlide, Swiper as SwpiperEle } from 'swiper/react'
import useSWRImmutable from 'swr/immutable'

import {
  FiBanner,
  HotCollections,
  LoanStats,
  MarketStats,
  Partners,
  PrivateSellNotificationBanner,
  RecentMint,
  Recommend,
  Royalties,
  Stats,
  Trending,
} from '@/components/homepage'
import { PageContainer } from '@/components/PageContainer'
import useCurrency from '@/hooks/useCurrency'
import { ApiRes } from '@/lib/api/types'
import { enableRecentMint } from '@/lib/features'
import { TE } from '@/lib/fp'
import { getFeaturedContracts, getTrendingContracts } from '@/lib/home/api'
import { http } from '@/lib/http'
import { estimateAPY } from '@/lib/x2y2/feeSharing'
import { PE } from '@/types'
import gsspGen from '@/utils/gsspGen'
import { requestConfig } from '@/utils/http'
import { DEFAULT_NETWORK } from '@/utils/network'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const HomePage: NextPage<Props> = ({
  stats,
  trendingContracts,
  featuredContracts,
}) => {
  const { t } = useTranslation()

  const { rates } = useCurrency()
  const { data: estimates } = useSWRImmutable({ rates }, ({ rates }) =>
    estimateAPY(DEFAULT_NETWORK, rates),
  )

  return (
    <PageContainer>
      <PrivateSellNotificationBanner />
      {enableRecentMint && <RecentMint />}
      <header className="relative">
        <div
          className={clsx(
            'absolute inset-0 -z-10 bg-gradient-to-b from-blue-bg to-bg',
            'after:mask-to-t after:absolute after:top-0 after:h-3/4 after:w-full after:bg-[url("/pattern.svg")] after:bg-[length:48px_48px] after:opacity-5',
          )}
        />
        <div className="mx-auto mt-10 mb-6 w-full max-w-[1440px] px-6 lg:mt-12 lg:mb-10 lg:px-8">
          <h2 className="ts-headline-4 text-gray-900 lg:ts-headline-2">
            {t('Buy, sell, and lend your NFTs')}
          </h2>
          <p className="ts-body-3 mt-2 text-gray-500 lg:ts-body-2">
            {t('Best place to liquify your illiquidity')}
          </p>
        </div>
      </header>
      {trendingContracts.length > 0 && (
        <section className="mx-auto mb-10 w-full max-w-[1440px] lg:mb-16">
          <Trending contracts={trendingContracts} />
        </section>
      )}
      <section className="mx-auto mb-10 w-full max-w-[1440px] lg:mb-16">
        <FiBanner />
      </section>
      {featuredContracts.length > 0 && (
        <section className="mx-auto mb-10 w-full max-w-[1440px] lg:mb-16">
          <Recommend contracts={featuredContracts} />
        </section>
      )}
      <section className="mx-auto w-full max-w-[1440px]">
        <Royalties />
      </section>
      <section className="mx-auto mt-10 w-full max-w-[1440px] lg:mt-16">
        <div className="mx-0 will-change-transform md:mx-6 lg:mx-8">
          <SwpiperEle
            modules={[Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={24}
            loop
          >
            {stats && estimates && (
              <SwiperSlide>
                <MarketStats stats={stats} estimates={estimates} />
              </SwiperSlide>
            )}
            <SwiperSlide>
              <LoanStats />
            </SwiperSlide>
          </SwpiperEle>
        </div>
      </section>
      {/* <section className="mx-auto w-full max-w-[1440px] py-10 lg:py-16">
        <h2 className="ts-headline-5 mx-6 lg:mx-8 lg:ts-headline-4">
          {t('Hot collections')}
        </h2>
        <HotCollections className="mt-3 lg:mt-6" />
      </section>
      <hr /> */}
      <section className="mx-auto w-full max-w-[1440px] py-10 lg:py-16">
        <h2 className="ts-headline-5 text-center lg:ts-headline-4">
          {t('Supported by')}
        </h2>
        <p className="ts-caption-2 mt-2 text-center text-gray-500 lg:ts-caption">
          {t('Our data providers and partners')}
        </p>
        <div className="mt-8">
          <Partners />
        </div>
      </section>
    </PageContainer>
  )
}

const pageTask = () =>
  pipe(
    TE.Do,
    TE.bind('stats', () =>
      pipe(
        TE.tryCatch(
          async () => {
            const res = await http.get<ApiRes<Stats>>(
              '/api/orders/stats',
              requestConfig(),
            )
            if (!res.data.success) throw 'error'
            return res.data.data
          },
          () => PE.internalError,
        ),
        TE.altW(() => TE.of(null)),
      ),
    ),
    TE.bind('trendingContracts', () =>
      pipe(
        TE.tryCatch(getTrendingContracts, () => PE.internalError),
        TE.altW(() => TE.of([])),
      ),
    ),
    TE.bind('featuredContracts', () =>
      pipe(
        TE.tryCatch(getFeaturedContracts, () => PE.internalError),
        TE.altW(() => TE.of([])),
      ),
    ),
  )

export const getServerSideProps = gsspGen(pageTask)

export default HomePage
